// extracted by mini-css-extract-plugin
export var aboutBkg = "about-module--aboutBkg--GaHuj";
export var aboutBody = "about-module--aboutBody--vOjAI";
export var aboutContainer = "about-module--aboutContainer--Vh1+I";
export var aboutHeader = "about-module--aboutHeader--NiXfb";
export var aboutLeft = "about-module--aboutLeft--snutL";
export var aboutRight = "about-module--aboutRight--fkur7";
export var arItem = "about-module--arItem--QWYFO";
export var bkgWrapper = "about-module--bkgWrapper--DaTrq";
export var heroContainer = "about-module--heroContainer--drIwf";
export var heroText = "about-module--heroText--RBsCm";
export var me = "about-module--me--m-DVf";
export var overviewInnerContainer = "about-module--overviewInnerContainer--XKhw0";
export var purpleText = "about-module--purpleText--pct5c";
export var rightBodyText = "about-module--rightBodyText--R1Eg0";
export var wave1 = "about-module--wave1--9-obM";
export var wave2 = "about-module--wave2--Rb+-z";