import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as miCasaStyles from "../styles/micasa.module.css"
import * as aboutStyles from "../styles/about.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"

const AboutMe = () => (

<main>

    <div id={miCasaStyles.topPageContainer}>
        <Header home={false}/>
        <Seo title="About me"/>
        <section id={aboutStyles.heroContainer}>
            <div id={aboutStyles.heroText}>
                It's me, Ryan
                <div id={miCasaStyles.heroSubtext}>
                I’m a software developer with a background in UX. I like solving complex, fiddly problems. 
                </div>
            </div>
                <StaticImage 
                    src="../assets/about/Ryan.jpg"
                    alt="Me"
                    className={aboutStyles.me}
                    quality={100}
                    loading="eager"
                />
            <div id={miCasaStyles.heroSubtextMobile}>
                I’m a software developer with a background in UX. I like solving complex, fiddly problems. 
            </div>
        </section>
    </div>

    <div id={aboutStyles.aboutBkg}>
            {/* <StaticImage 
                src="../assets/about/wave.svg"
                alt="Me"
                className={aboutStyles.wave1}
                quality={100}
                loading="eager"
                placeholder="tracedSVG"
            />
            <StaticImage 
                src="../assets/about/wave.svg"
                alt="Me"
                className={aboutStyles.wave2}
                quality={100}
                loading="eager"
                placeholder="tracedSVG"
            /> */}
        
        <div id={aboutStyles.overviewInnerContainer}>
            <div class={aboutStyles.aboutContainer}>
                <div id={aboutStyles.aboutLeft}>

                    <div class={aboutStyles.aboutHeader}>
                        About
                    </div>
                    <div class={miCasaStyles.bodyText} id={aboutStyles.aboutBody}>
                        In a former life, I worked in the human rights sector as a digital communications officer, gaining skills in writing and design. In 2021, I graduated from Georgia Institute of Technology with a Master’s in Human Computer Interaction, <span class={aboutStyles.purpleText}>specializing in building mixed reality applications in Unity.</span>
                        <br/><br/>
                        In my free time, I enjoy participating in game jams and creative coding (primarily in P5 JS), with inspiration from Daniel Shiffman and work like Dave Whyte’s Bees and Bombs. I’m also an avid gamer, chess player, and pickleball player.
                        <br/><br/>
                        I currently work as a software engineer at Volkswagen's Innovation and Engineering Center in California, developing mixed reality cockpit experiences to rapidly prototype and iterate on new transportation designs.
                    </div>

                </div>

                <div id={aboutStyles.aboutRight}>
                    <div class={aboutStyles.arItem}>
                        <div class={aboutStyles.aboutHeader}>
                            Resume
                        </div>
                        <div class={`${miCasaStyles.bodyText} ${aboutStyles.rightBodyText}`}>
                        <a href={"../../ResumePortfolio.pdf"}>Online PDF</a>
                        </div>
                    </div>

                    <div class={aboutStyles.arItem}>
                        <div class={aboutStyles.aboutHeader}>
                            Contact
                        </div>
                        <div class={`${miCasaStyles.bodyText} ${aboutStyles.rightBodyText}`}>
                            <span style={{textDecoration: "underline"}}>winsteadwork@gmail.com</span>
                        </div>
                    </div>

                    <div class={aboutStyles.arItem}>
                        <div class={aboutStyles.aboutHeader}>
                            Currently reading
                        </div>
                        <div class={`${miCasaStyles.bodyText} ${aboutStyles.rightBodyText}`}>
                            Stormlight Archives by Brandon Sanderson
                        </div>
                    </div>

                    <div class={aboutStyles.arItem}>
                        <div class={aboutStyles.aboutHeader}>
                            Currently playing
                        </div>
                        <div class={`${miCasaStyles.bodyText} ${aboutStyles.rightBodyText}`}>
                            Age of Empires IV, Super Mario Bros. Wonder
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
    <Footer/>


</main>

)

export default AboutMe